import { useDispatch, useSelector } from "react-redux";
import { addFilters } from "../../redux/slices/media-slice";
import Konva from "konva";

const FilterOptions = () => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.media);

  return (
    <div className=" flex bg-white  w-64 z-50 absolute top-14 left-3 rounded shadow-lg">
      <div className="p-3 grid grid-cols-3 gap-2 cursor-pointer w-full">
        <div
          onClick={() =>
            dispatch(addFilters({ type: "blur", effect: Konva.Filters.Blur }))
          }
          className={` ${
            filters.some((filter) => filter.type === "blur") && "text-blue-500"
          } text-center gap-2 text-xs capitalize hover:text-blue-300 py-2 px-1`}
        >
          Blur
        </div>
        <div
          onClick={() =>
            dispatch(
              addFilters({ type: "grayscale", effect: Konva.Filters.Grayscale })
            )
          }
          className={` ${
            filters.some((filter) => filter.type === "grayscale") &&
            "text-blue-500"
          } text-center gap-2 text-xs capitalize hover:text-blue-300 py-2 px-1`}
        >
          Grayscale
        </div>
        <div
          onClick={() =>
            dispatch(addFilters({ type: "sepia", effect: Konva.Filters.Sepia }))
          }
          className={` ${
            filters.some((filter) => filter.type === "sepia") && "text-blue-500"
          } text-center gap-2 text-xs capitalize hover:text-blue-300 py-2 px-1`}
        >
          Sepia
        </div>

        <div
          onClick={() =>
            dispatch(
              addFilters({ type: "pixelate", effect: Konva.Filters.Pixelate })
            )
          }
          className={` ${
            filters.some((filter) => filter.type === "pixelate") &&
            "text-blue-500"
          } text-center gap-2 text-xs capitalize hover:text-blue-300 py-2 px-1`}
        >
          pixelate
        </div>
        <div
          onClick={() =>
            dispatch(
              addFilters({
                type: "kaleidoscope",
                effect: Konva.Filters.Kaleidoscope,
              })
            )
          }
          className={` ${
            filters.some((filter) => filter.type === "kaleidoscope") &&
            "text-blue-500"
          } text-center gap-2 text-xs capitalize hover:text-blue-300 py-2 px-1`}
        >
          kaleidoscope
        </div>
        <div
          onClick={() =>
            dispatch(
              addFilters({
                type: "emboss",
                effect: Konva.Filters.Emboss,
              })
            )
          }
          className={` ${
            filters.some((filter) => filter.type === "emboss") &&
            "text-blue-500"
          } text-center gap-2 text-xs capitalize hover:text-blue-300 py-2 px-1`}
        >
          emboss
        </div>
        <div
          onClick={() =>
            dispatch(
              addFilters({
                type: "invert",
                effect: Konva.Filters.Invert,
              })
            )
          }
          className={` ${
            filters.some((filter) => filter.type === "invert") &&
            "text-blue-500"
          } text-center gap-2 text-xs capitalize hover:text-blue-300 py-2 px-1`}
        >
          invert
        </div>
      </div>
    </div>
  );
};

export default FilterOptions;
