import React from "react";
import { GoUpload } from "react-icons/go";
import FileList from "./FileList";

const Files = () => {
  return (
    <div className="p-3">
      <label
        htmlFor="imageUpload"
        className="bg-gray-300 px-3 mb-3 justify-center py-3 cursor-pointer font-semibold text-sm rounded uppercase flex items-center text-gray-800 gap-3"
      >
        Upload your file
        <GoUpload className="font-semibold" size={16} />
      </label>
      <input id="imageUpload" type="file" accept="/image*" className="hidden" />
      <hr />
      <FileList />
    </div>
  );
};

export default Files;
