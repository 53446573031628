import { useEffect, useRef } from "react";
import { Star, Transformer } from "react-konva";

const StarShape = ({ shape, handleClick, selected }) => {
  console.log(shape);
  const starRef = useRef();
  const trRef = useRef();
  useEffect(() => {
    if (selected) {
      trRef.current?.nodes([starRef.current]);
      trRef.current?.getLayer()?.batchDraw();
    } else {
      trRef.current?.nodes([]);
      trRef.current?.getLayer()?.batchDraw();
    }
  }, [selected]);
  return (
    <>
      <Star
        onClick={handleClick}
        ref={starRef}
        x={shape.x}
        y={shape.y}
        numPoints={shape.numPoints}
        innerRadius={shape.innerRadius}
        outerRadius={shape.outerRadius}
        stroke={shape.fill}
        strokeWidth={shape.strokeWidth}
        fill={shape.fill}
        draggable
      />
      <Transformer
        ref={trRef}
        resizeEnabled={true}
        rotateEnabled={true}
        boundBoxFunc={(oldBox, newBox) => {
          if (newBox.width < 9 || newBox.height < 9) {
            return oldBox;
          }
          return newBox;
        }}
      />
    </>
  );
};

export default StarShape;
