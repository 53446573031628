import { useEffect, useState } from "react";
// import { EditableTextInput } from "./EditableTextInput";
import { ResizableText } from "./ResizeableText";
import { useDispatch } from "react-redux";
import { addSelectedFont, editText } from "../../redux/slices/media-slice";

const RETURN_KEY = 13;
const ESCAPE_KEY = 27;

export function EditableText({
  item,
  isEditing,
  onEdit,
  template,
  handleText,
}) {
  const [width, setWidth] = useState(260);
  const [height, setHeight] = useState(70);
  const [isTransforming, setIsTransforming] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing == null) {
      setIsTransforming(false);
    }
  }, [isEditing]);

  const onTextClick = () => {
    if (!isEditing) {
      // setIsTransforming(!isTransforming);
      onEdit(item.id);
    }
  };

  const toggleTransforming = () => {
    setIsTransforming(!isTransforming);
    dispatch(addSelectedFont(item.id));
  };

  const onTextResize = (newWidth, newHeight) => {
    setWidth(newWidth);
    setHeight(newHeight);
  };

  const handleEscapeKeys = (e) => {
    if ((e.keyCode === RETURN_KEY && !e.shiftKey) || e.keyCode === ESCAPE_KEY) {
      onEdit(null); // Exit editing mode when escape or return is pressed
    }
  };

  const handleTextChange = (e) => {
    if (template) {
      handleText(e.currentTarget.value);
    } else {
      dispatch(editText({ id: item.id, text: e.currentTarget.value }));
    }
  };
  console.log(isEditing);
  // if (isEditing) {
  //   return (
  //     <EditableTextInput
  //       x={item.x}
  //       y={item.y}
  //       width={width}
  //       height={height}
  //       value={item.text}
  //       size={item.fontSize}
  //       onChange={handleTextChange}
  //       onKeyDown={handleEscapeKeys}
  //       // onclick={toggleEdit}
  //     />
  //   );
  // }
  return (
    <ResizableText
      editing={isEditing}
      value={item.text}
      size={item.fontSize}
      x={item.x}
      y={item.y}
      id={item.id}
      fontSize={item.fontSize}
      font={item.fontFamily}
      isSelected={isTransforming}
      onChange={handleTextChange}
      onKeyDown={handleEscapeKeys}
      onClick={toggleTransforming}
      onDoubleClick={onTextClick}
      onResize={onTextResize}
      text={item.text}
      width={width}
      fill={item.fill}
      height={height}
    />
  );
}
