import { useEffect, useRef, useState } from "react";
import { Image, Transformer } from "react-konva";
import { useSelector } from "react-redux";
import useImage from "use-image";

const LionImage = ({ containerWidth, containerHeight, imageLoaded, close }) => {
  const [image] = useImage(imageLoaded);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const imageRef = useRef();
  const trRef = useRef();
  const [selected, setSelected] = useState(false);
  const { filters, horizontalFlip, verticalFlip } = useSelector(
    (state) => state.media
  );

  const [crop, setCrop] = useState({
    width: 150,
    height: 130.0567132720692,
    x: 50,
    y: 10,
  });
  useEffect(() => {
    if (image && imageRef.current) {
      imageRef.current.cache();
      imageRef.current.getLayer().batchDraw();
      const naturalWidth = image.width;
      const naturalHeight = image.height;
      const widthScale = containerWidth / naturalWidth;
      const heightScale = containerHeight / naturalHeight;

      const scale = Math.min(widthScale, heightScale);

      setImageDimensions({
        width: naturalWidth * scale,
        height: naturalHeight * scale,
      });
    }
  }, [image, filters]);
  console.log(image);
  useEffect(() => {
    if (selected) {
      trRef.current?.nodes([imageRef.current]);
      trRef.current?.getLayer()?.batchDraw();
    } else {
      trRef.current?.nodes([]);
      trRef.current?.getLayer()?.batchDraw();
    }
  }, [selected]);

  const getCrop2 = (image, newSize) => {
    const aspectRatio = newSize.width / newSize.height;
    const imageRatio = image.width / image.height;

    let newWidth =
      aspectRatio >= imageRatio ? image.width : image.height * aspectRatio;
    let newHeight =
      aspectRatio >= imageRatio ? image.width / aspectRatio : image.height;

    let x = (image.width - newWidth) / 2;
    let y = (image.height - newHeight) / 2;

    return {
      x: x,
      y: y,
      width: newWidth,
      height: newHeight,
    };
  };

  const transformar = (end) => {
    const node = imageRef.current;

    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    let newWidth = Math.max(5, node.width() * scaleX);
    let newHeight = Math.max(node.height() * scaleY);

    node?.scaleX(1);
    node?.scaleY(1);

    if (end) {
      console.log(end);
    } else {
      node?.width(newWidth);
      node?.height(newHeight);
      const activeAnchor = trRef.current.getActiveAnchor();
      const crop = getCrop2(
        { width: image?.width, height: image?.height },
        { width: newWidth, height: newHeight }
      );

      console.log(crop);
      node.crop(crop);
    }
  };

  return (
    <>
      <Image
        onClick={() => {
          if (close) close();
          setSelected(!selected);
        }}
        ref={imageRef}
        image={image}
        scaleY={verticalFlip.scale}
        scaleX={horizontalFlip.scale}
        draggable
        filters={filters.map((item) => item.effect)}
        width={imageDimensions.width}
        blurRadius={20}
        height={imageDimensions.height}
        y={verticalFlip.y ? imageDimensions.height : undefined}
        x={horizontalFlip.x ? imageDimensions.width : undefined}
        crop={getCrop2(
          { width: image?.width, height: image?.height },
          { width: crop.width, height: crop.height }
        )}
        onTransform={(e) => {
          transformar(false);
        }}
        // crop={{
        //   x: cropPosition.x,
        //   y: cropPosition.y,
        //   width: cropPosition.width,
        //   height: cropPosition.height,
        // }}
      />
      <Transformer
        ref={trRef}
        resizeEnabled={true}
        rotateEnabled={true}
        boundBoxFunc={(oldBox, newBox) => {
          if (newBox.width < 9 || newBox.height < 9) {
            return oldBox;
          }
          return newBox;
        }}
      />
    </>
  );
};

// Functional Component
const App = ({ image, close }) => {
  return (
    <div className="flex h-[30vh]">
      <div
        className="bg-slate-500 h-[30vh] flex-1"
        style={{ position: "relative" }}
      >
        {/* <Stage width={window.innerWidth / 2} height={window.innerHeight}> */}

        <LionImage
          close={close}
          imageLoaded={image}
          containerWidth={500}
          // containerWidth={containerWidth}
          containerHeight={300}
        />
      </div>
    </div>
  );
};

const ImageValue = ({ image, close }) => <App close={close} image={image} />;

export default ImageValue;
