import React from "react";

const VideoList = () => {
  return (
    <div className="py-3">
      <p className="text-gray-300">Uploaded video list goes here in cards </p>
    </div>
  );
};

export default VideoList;
