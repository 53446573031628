import React from "react";
import { LuFlipHorizontal2, LuFlipVertical2 } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import {
  addHorizontalFlip,
  addVerticalFlip,
} from "../../redux/slices/media-slice";

const FlipOption = () => {
  const { horizontalFlip, verticalFlip } = useSelector((state) => state.media);
  const dispatch = useDispatch();
  return (
    <div className=" flex bg-white  w-52 z-50 absolute top-14 left-3 rounded shadow-lg">
      <div className="p-3 flex flex-col gap-2 cursor-pointer w-full">
        <div
          onClick={() => dispatch(addHorizontalFlip())}
          className={`flex items-center gap-2 text-xs capitalize ${
            horizontalFlip.scale && "text-blue-500"
          } hover:text-blue-300 py-2 px-1`}
        >
          <LuFlipHorizontal2 /> Flip Horizontally
        </div>
        <div
          onClick={() => dispatch(addVerticalFlip())}
          className={`flex items-center gap-2 text-xs capitalize ${
            verticalFlip.scale && "text-blue-500"
          } hover:text-blue-300 py-2 px-1`}
        >
          <LuFlipVertical2 /> Flip Vertically
        </div>
      </div>
    </div>
  );
};

export default FlipOption;
