import "./GraphicOne.css"; // Import your CSS file for styles
import ImageValue from "../imageBoard/ImageValue";
import { EditableText } from "../text/EditableText";
import { forwardRef, useImperativeHandle, useState } from "react";

const GraphicOne = forwardRef(({ info }, ref) => {
  const [editingId, setEditingId] = useState(null);
  const [texts, setText] = useState(info.text);

  const handleTextUpdate = (newText) => {
    setText((prev) => {
      const oldTexts = [...prev];

      const textIndex = oldTexts.findIndex((item) => item.id === editingId);

      if (textIndex !== -1) {
        oldTexts[textIndex] = {
          ...oldTexts[textIndex],
          text: newText,
        };
      }

      return oldTexts;
    });
  };
  useImperativeHandle(ref, () => ({
    childFunction() {
      setEditingId(null);
    },
  }));
  const resetEditing = () => {
    setEditingId(null);
  };
  return (
    <>
      <ImageValue image={info.image} close={resetEditing} />
      {texts.map((item, index) => (
        <EditableText
          item={item}
          key={index}
          x={20}
          y={40}
          handleText={handleTextUpdate}
          template={true}
          isEditing={editingId === item.id}
          onEdit={setEditingId}
        />
      ))}
    </>
  );
});
GraphicOne.displayName = "GraphicOne";
export default GraphicOne;
