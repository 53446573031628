import { useState } from "react";
import { BsCardHeading } from "react-icons/bs";
import {
  FaCircle,
  FaSquareFull,
  FaHeart,
  FaCross,
  FaArrowDown,
  FaArrowLeft,
  FaArrowUp,
  FaArrowRight,
} from "react-icons/fa";
import { IoTriangle } from "react-icons/io5";
import { IoIosStar } from "react-icons/io";
import { BiSolidPolygon } from "react-icons/bi";
import { MdBrush, MdOutlineInsertPhoto } from "react-icons/md";
import { RiFontSize } from "react-icons/ri";
import { TbLetterT } from "react-icons/tb";
import { SketchPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import {
  addColor,
  addShapes,
  addTemplate,
  addText,
  editFont,
  showOnBoard,
} from "../../redux/slices/media-slice";
import imagesArray from "../../../imagedata";

const Basic = () => {
  const [shapes, setShapes] = useState(0);
  const [texts, setTexts] = useState(0);
  const [currentColor, setCurrentColor] = useState("#000000");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showTextOptions, setShowTextOptions] = useState(false);
  const [showFontOptions, setShowFontOptions] = useState(false); // Toggle for showing font options
  const [selectedFont, setSelectedFont] = useState("Arial"); // State to store selected font
  const [options, setOptions] = useState("text");
  const dispatch = useDispatch();

  const { selectedText } = useSelector((state) => state.media);

  // List of fonts to display
  const fontOptions = [
    { name: "Arial", style: { fontFamily: "Arial" } },
    { name: "Roboto", style: { fontFamily: "'Roboto', sans-serif" } },
    { name: "Georgia", style: { fontFamily: "Georgia, serif" } },
    { name: "Courier New", style: { fontFamily: "'Courier New', monospace" } },
    { name: "Pacifico", style: { fontFamily: "'Pacifico', cursive" } },
  ];

  const handleColorChange = (color) => {
    dispatch(addColor(color));
    setCurrentColor(color);
  };

  // Handle adding a square
  const handleSquareClick = () => {
    const id = shapes + 1;
    setShapes((prev) => prev + 1);
    const newSquare = {
      id: id,
      type: "square",
      x: 50,
      y: 50,
      width: 100,
      height: 100,
      fill: currentColor,
    };

    dispatch(addShapes(newSquare));
  };

  // Handle adding a triangle
  const handleTriangleClick = () => {
    const id = shapes + 1;
    setShapes((prev) => prev + 1);
    const newTriangle = {
      id: id,
      type: "triangle",
      x: 150,
      y: 150,
      sides: 3,
      radius: 50,
      fill: currentColor,
    };

    dispatch(addShapes(newTriangle));
  };

  // Handle adding a star

  const handleStarClick = () => {
    console.log("entered here");

    const id = shapes + 1;
    setShapes((prev) => prev + 1);
    const newStar = {
      id: id,
      type: "star",
      x: 150,
      y: 150,
      numPoints: 5,
      innerRadius: 30,
      outerRadius: 70,
      strokeWidth: 4,
      fill: currentColor,
    };

    dispatch(addShapes(newStar));
  };

  // Handle adding a circle
  const handleCircleClick = () => {
    const id = shapes + 1;
    setShapes((prev) => prev + 1);
    const newCircle = {
      id: id,
      type: "circle",
      x: 250,
      y: 250,
      radius: 50,
      fill: currentColor,
    };

    dispatch(addShapes(newCircle));
  };

  // Handle adding text (Header, Subheader, Body Text)
  const handleAddText = (type) => {
    let newText;
    const id = texts + 1;
    setTexts((prev) => prev + 1);
    if (type === "h1") {
      newText = {
        id: id,
        type: "text",
        text: "Header (H1)",
        fontSize: 36,
        fontFamily: selectedFont, // Apply selected font
        x: 50,
        y: 50,
        fill: currentColor,
      };
    } else if (type === "h2") {
      newText = {
        id: id,
        type: "text",
        text: "Subheader (H2)",
        fontSize: 30,
        fontFamily: selectedFont, // Apply selected font
        x: 50,
        y: 100,
        fill: currentColor,
      };
    } else if (type === "p") {
      newText = {
        id: id,
        type: "text",
        text: "Body Text (P)",
        fontSize: 18,
        fontFamily: selectedFont, // Apply selected font
        x: 50,
        y: 150,
        fill: currentColor,
      };
    }
    dispatch(addText(newText));
    setShowTextOptions(false);
  };

  const setFont = (font) => {
    setSelectedFont(font);
    dispatch(editFont({ id: selectedText, fontFamily: font }));
  };

  return (
    <div className="p-3">
      {/* Toolbar with shape icons */}
      <div className="flex gap-2">
        <div
          onClick={() => setOptions("text")}
          className={` ${
            options == "text" ? "bg-gray-500" : "bg-gray-800"
          } cursor-pointer hover:bg-gray-500 h-10 w-10 flex items-center rounded justify-center text-gray-300`}
        >
          <span className="font-semibold text-xl">
            <TbLetterT />
          </span>
        </div>

        {/* Add Square */}
        <div
          className="bg-gray-800 cursor-pointer hover:bg-gray-500 h-10 w-10 flex items-center rounded justify-center text-gray-300"
          onClick={handleSquareClick}
        >
          <span className="font-semibold text-xl">
            <FaSquareFull />
          </span>
        </div>

        {/* Add Triangle */}
        <div
          className="bg-gray-800 cursor-pointer hover:bg-gray-500 h-10 w-10 flex items-center rounded justify-center text-gray-300"
          onClick={handleTriangleClick}
        >
          <span className="font-semibold text-xl">
            <IoTriangle />
          </span>
        </div>

        {/* Add Circle */}
        <div
          className="bg-gray-800 cursor-pointer hover:bg-gray-500 h-10 w-10 flex items-center rounded justify-center text-gray-300"
          onClick={handleCircleClick}
        >
          <span className="font-semibold text-xl">
            <FaCircle />
          </span>
        </div>

        {/* Color Picker */}
        <div className="relative">
          <div
            className="bg-gray-800 cursor-pointer hover:bg-gray-500 h-10 w-10 flex items-center rounded justify-center text-gray-300"
            onClick={() => setShowColorPicker(!showColorPicker)} // Toggle the color picker
          >
            <span className="font-semibold text-xl">
              <MdBrush />
            </span>
          </div>
          {showColorPicker && (
            <div className="absolute z-10">
              <SketchPicker
                color={currentColor}
                onChange={(color) => handleColorChange(color.hex)} // Update the selected color
              />
            </div>
          )}
        </div>

        {/* Re-added MdOutlineCallMade icon */}
        <div
          onClick={() => setOptions("images")}
          className={` ${
            options == "images" ? "bg-gray-500" : "bg-gray-800"
          } cursor-pointer hover:bg-gray-500 h-10 w-10 flex items-center rounded justify-center text-gray-300`}
        >
          <span className="font-semibold text-xl">
            <MdOutlineInsertPhoto />
          </span>
        </div>
      </div>

      {/* Heading and Text */}
      {options == "text" && (
        <div className="py-4 flex flex-col gap-2">
          {/* Add Text Button */}
          {/* <div
            className="text-gray-200 flex cursor-pointer py-3 gap-3 items-center justify-center shadow-md rounded bg-gray-800"
            onClick={handleTextAdd} // Toggle text options
          >
            <BiPlus size={30} />
            <span className="font-semibold text-sm capitalize">Add text</span>
          </div> */}
          {/* Heading & Text Button */}
          <div
            className="text-gray-200 flex cursor-pointer py-3 gap-3 items-center justify-center shadow-md rounded bg-gray-800"
            onClick={() => setShowTextOptions(!showTextOptions)} // Toggle text options
          >
            <BsCardHeading size={30} />
            <span className="font-semibold text-sm capitalize">
              heading & text
            </span>
          </div>

          {/* Text Options */}
          {showTextOptions && (
            <div className="text-options bg-gray-700 p-3 rounded shadow-md">
              <div
                className="text-option cursor-pointer text-gray-200 py-1"
                onClick={() => handleAddText("h1")}
              >
                Create Header (H1)
              </div>
              <div
                className="text-option cursor-pointer text-gray-200 py-1"
                onClick={() => handleAddText("h2")}
              >
                Create Subheader (H2)
              </div>
              <div
                className="text-option cursor-pointer text-gray-200 py-1"
                onClick={() => handleAddText("p")}
              >
                Create Body Text (P)
              </div>
            </div>
          )}

          {/* Font Pairs Button */}
          <div
            className="text-gray-200 flex cursor-pointer py-3 gap-3 items-center justify-center shadow-md rounded bg-gray-800"
            onClick={() => setShowFontOptions(!showFontOptions)} // Toggle font options
          >
            <RiFontSize size={30} />
            <span className="font-semibold text-sm capitalize">Font pairs</span>
          </div>

          {/* Font Options */}
          {showFontOptions && (
            <div className="font-options bg-gray-700 p-3 rounded shadow-md">
              {fontOptions.map((font) => (
                <div
                  key={font.name}
                  className="font-option cursor-pointer text-gray-200 py-1"
                  style={font.style} // Apply font style
                  onClick={() => setFont(font.style.fontFamily)} // Select the font
                >
                  {font.name}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {options == "images" && (
        <div className="grid grid-cols-2 gap-4 h-[70vh] p-4 overflow-auto">
          {imagesArray.map((item, index) => (
            <img
              onClick={() => dispatch(showOnBoard(item))}
              src={item}
              key={index}
              className="rounded-md shadow-md cursor-pointer hover:opacity-75"
            />
          ))}
        </div>
      )}

      <div className="grid grid-cols-2 gap-4 h-[40vh] p-4 overflow-auto">
        <img
          onClick={() =>
            dispatch(
              addTemplate({
                image: "/template/plant.png",
                text: [
                  {
                    id: 1,
                    text: "Identify the essential",
                    x: 50,
                    y: 100,
                    fontSize: 24,
                    fontFamily: "monospace",
                  },
                  {
                    id: 2,
                    text: "Eliminate the rest",
                    x: 50,
                    y: 150,
                    fontSize: 24,
                    fontFamily: "Georgia, 'Times New Roman', Times, serif",
                  },
                  {
                    id: 3,
                    text: "done",
                    x: 400,
                    y: 250,
                    fontSize: 40,
                    fill: "green",
                  },
                ],
              })
            )
          }
          src="/template/plant.png"
          className="rounded-md shadow-md cursor-pointer hover:opacity-75"
        />
      </div>
      {/* shapes */}
      {/* <div
        className="flex flex-wrap justify-between"
        style={{ height: "400px", overflowY: "scroll" }}
      >
        <span className="font-semibold text-9xl">
          <IoIosStar onClick={handleStarClick} />
        </span>

        <span className="font-semibold text-9xl">
          <BiSolidPolygon />
        </span>

        <span className="font-semibold text-9xl">
          <FaHeart />
        </span>

        <span className="font-semibold text-9xl">
          <FaCross />
        </span>

        <span className="font-semibold text-9xl">
          <FaArrowDown />
        </span>

        <span className="font-semibold text-9xl">
          <FaArrowLeft />
        </span>

        <span className="font-semibold text-9xl">
          <FaArrowRight />
        </span>

        <span className="font-semibold text-9xl">
          <FaArrowUp />
        </span>
      </div> */}

      {/* Konva Canvas for drawing shapes */}
    </div>
  );
};

export default Basic;
