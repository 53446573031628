import { forwardRef, useEffect, useRef, useState } from "react";
import BackgroundOption from "../hedaerBoard/BackgroundOption";
import FlipOption from "../hedaerBoard/FlipOption";
import ScaleOption from "../hedaerBoard/ScaleOption";
import { useDispatch, useSelector } from "react-redux";
import ImageValue from "../imageBoard/ImageValue";
import { Layer, Stage } from "react-konva";
import FilterOptions from "../hedaerBoard/FilterOptions";
import Rectangle from "../shapes/Rectangle";
import Triangle from "../shapes/Triangle";
import CircleComponant from "../shapes/Circle";
import { addSelected } from "../../redux/slices/media-slice";
import { EditableText } from "../text/EditableText";
import StarShape from "../shapes/Star";
import GraphicOne from "../templateImages/GraphicOne";

const EditingBoard = forwardRef(({ fetchedBackground }, ref) => {
  const [menu, setMenu] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const childRef = useRef(null);
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const updateContainerSize = () => {
    if (containerRef.current) {
      // const container = containerRef.current.getBoundingClientRect();
      // setContainerWidth(container.width);
      // setContainerHeight(container.height);
    }
  };

  useEffect(() => {
    updateContainerSize();
    window.addEventListener("resize", updateContainerSize);

    return () => {
      window.removeEventListener("resize", updateContainerSize);
    };
  }, []);

  const { image, template, shapes, selected, texts } = useSelector(
    (state) => state.media
  );
  // const { shapes } = useSelector((state) => state.media);
  // const { selected } = useSelector((state) => state.media);
  // const { texts } = useSelector((state) => state.media);
  console.log("template", template);
  const handleShapeSelection = (id) => {
    dispatch(addSelected(id));
    // setSelected((prev) => {
    //   console.log(prev);
    //   if (prev !== index) {
    //     return index;
    //   } else {
    //     return null;
    //   }
    // });
  };
  console.log("shapes", shapes);
  return (
    <div>
      <div className="py-2 px-3 bg-slate-100 flex items-center gap-4 text-xs text-gray-800 font-semibold">
        <div className="relative ">
          <button
            className={`hover:bg-gray-300 rounded-3xl p-3 outline-none ${
              menu == "background" ? "text-indigo-600 bg-gray-300" : ""
            }`}
            onClick={() => setMenu("background")}
          >
            Background
          </button>
          {menu == "background" && (
            <BackgroundOption background={fetchedBackground} />
          )}
        </div>
        <div className="relative">
          <button
            className={`hover:bg-gray-300 rounded-3xl p-3 outline-none ${
              menu == "filter" ? "text-indigo-600 bg-gray-300" : ""
            }`}
            onClick={() =>
              setMenu((prev) => {
                if (prev == "filter") {
                  return "";
                } else {
                  return "filter";
                }
              })
            }
          >
            Filter
          </button>
          {menu == "filter" && <FilterOptions />}
        </div>
        <div className="relative">
          <button
            className={`hover:bg-gray-300 rounded-3xl p-3 outline-none ${
              menu == "flip" ? "text-indigo-600 bg-gray-300" : ""
            }`}
            onClick={() =>
              setMenu((prev) => {
                if (prev == "flip") {
                  return "";
                } else {
                  return "flip";
                }
              })
            }
          >
            Flip
          </button>
          {menu == "flip" && <FlipOption />}
        </div>
        <div className="relative">
          <button
            className={`hover:bg-gray-300 rounded-3xl p-3 outline-none ${
              menu == "scale" ? "text-indigo-600 bg-gray-300" : ""
            }`}
            onClick={() => setMenu("scale")}
          >
            Scale
          </button>
          {menu == "scale" && <ScaleOption />}
        </div>

        <button className="hover:bg-gray-300 rounded-3xl p-3">
          Remove Background
        </button>
      </div>

      <div
        onClick={() => setMenu(null)}
        className="bg-gray-400 w-[500px] h-[300px] mt-10 relative left-40"
      >
        <Stage
          width={500}
          height={300}
          ref={ref}
          onClick={(e) => {
            if (e.currentTarget._id === e.target._id) {
              setEditingId(null);
              if (childRef.current) {
                childRef.current.childFunction();
              }
            }
          }}
        >
          <Layer>
            {template && <GraphicOne ref={childRef} info={template} />}
            {image.map((item, index) => (
              <ImageValue key={index} image={item} />
            ))}
            {texts.map((item, index) => (
              <EditableText
                item={item}
                key={index}
                x={20}
                y={40}
                isEditing={editingId === item.id}
                onEdit={setEditingId}
              />
            ))}

            {shapes.map((shape, index) => {
              if (shape.type === "square") {
                return (
                  <Rectangle
                    key={index}
                    shape={shape}
                    handleClick={() => handleShapeSelection(shape.id)}
                    selected={shape.id == selected}
                  />
                );
              } else if (shape.type === "triangle") {
                return (
                  <Triangle
                    shape={shape}
                    key={index}
                    handleClick={() => handleShapeSelection(shape.id)}
                    selected={shape.id == selected}
                  />
                );
              } else if (shape.type === "circle") {
                return (
                  <CircleComponant
                    shape={shape}
                    key={index}
                    handleClick={() => handleShapeSelection(shape.id)}
                    selected={shape.id == selected}
                  />
                );
              } else if (shape.type === "star") {
                return (
                  <StarShape
                    shape={shape}
                    key={index}
                    handleClick={() => handleShapeSelection(shape.id)}
                    selected={shape.id == selected}
                  />
                );
              }
              return null;
            })}
          </Layer>
        </Stage>
      </div>

      {/* <div className="relative">
        <div className=" fixed bottom-0 flex items-center justify-between bg-white px-2 py-4 w-[70%] px-2">
          <div>00:00:00</div>
          <div className="flex items-center">
            <div>
              <MdOutlineSkipPrevious />
            </div>
            <div>
              <MdPlayCircle size={36} />
            </div>
            <div>
              <MdOutlineSkipNext />
            </div>
          </div>
          <div>
            <MdCancel />
          </div>
        </div>
      </div> */}
    </div>
  );
});

EditingBoard.displayName = "EditingBoard";
export default EditingBoard;
