import React, { useState } from "react";
import coffee from "../../assets/img/coffee.png";
import ImageValue from "../imageBoard/ImageValue";
import { useDispatch } from "react-redux";
import { showOnBoard } from "../../redux/actions";

const PhotoList = ({ list }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  console.log(list);
  const dispatch = useDispatch();

  const handleImageClick = (image) => {
    // setSelectedImage(image);
    dispatch(showOnBoard(image));
  };

  return (
    <div className="py-3">
      <p className="text-gray-300">Uploaded photo list goes here in cards </p>

      <div className="grid grid-cols-2 gap-3 py-2 h-[65vh] overflow-y-auto">
        <img
          src={coffee}
          className="w-28 h-28 cursor-pointer"
          onClick={() => handleImageClick(coffee)}
          alt="Coffee"
        />
        {list.map((item, index) => (
          <img
            key={index}
            src={item.preview}
            className="w-28 h-28 cursor-pointer"
            onClick={() => handleImageClick(item.preview)}
            alt="Coffee"
          />
        ))}
      </div>
    </div>
  );
};

export default PhotoList;
