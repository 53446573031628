import React, { useState } from "react";
import { GoUpload } from "react-icons/go";
import PhotoList from "./PhotoList";

const Photos = () => {
  const [photoList, setPhotoList] = useState([]);

  const handleFiles = (e) => {
    const files = Array.from(e.target.files);
    const updatedFiles = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setPhotoList((prevPhotos) => [...prevPhotos, ...updatedFiles]);
  };
  return (
    <div className="p-3">
      <label
        htmlFor="imageUpload"
        className="bg-gray-300 px-3 mb-3 justify-center py-3 cursor-pointer font-semibold text-sm rounded uppercase flex items-center text-gray-800 gap-3"
      >
        Upload your image
        <GoUpload className="font-semibold" size={16} />
      </label>
      <input
        id="imageUpload"
        type="file"
        accept=".jpg,.jpeg,.png"
        className="hidden"
        multiple
        onChange={handleFiles}
      />

      <hr />
      <PhotoList list={photoList} />
    </div>
  );
};

export default Photos;
