import { Html } from "react-konva-utils";

const getStyle = (width, height, size, fontFamily, fill) => {
  console.log(height, width);
  const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
  const baseStyle = {
    // width: `${width}px`,
    // height: "",
    width: `${width}px`,
    // height: `${height}px`,
    border: "none",
    padding: "0px",
    margin: "0px",
    background: "none",
    outline: "none",
    resize: "none",
    color: fill ? fill : "black",
    fontSize: `${size}px`,
    fontFamily: fontFamily,
  };
  if (isFirefox) {
    return baseStyle;
  }
  return {
    ...baseStyle,
    margintop: "-4px",
  };
};

export const EditableTextInput = ({
  x,
  y,
  width,
  height,
  value,
  onChange,
  onKeyDown,
  onClick,
  fill,
  size,
  fontFamily,
}) => {
  const style = getStyle(width, height, size, fontFamily, fill);
  return (
    <Html groupProps={{ x, y }} divProps={{ style: { opacity: 1 } }}>
      <textarea
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        // onClick={onClick}
        style={style}
      />
    </Html>
  );
};
