import React from "react";
import { BiRedo, BiUndo } from "react-icons/bi";
import { FaCrown, FaShareAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { redo, undo } from "../../redux/slices/media-slice";
import { Link } from "react-router-dom";
import brandlogo from "../../assets/images/swiftsketch-desktop-tinted.png";

export const Header = ({ initiateDownload }) => {
  const dispatch = useDispatch();
  return (
    <div className="dark:bg-slate-950/50 flex px-4 items-center justify-between py-3 text-white">
      <div className="flex items-center gap-4">
        <button
          onClick={() => dispatch(undo())}
          className="flex hover:bg-gray-800 px-3 items-center gap-1 capitalize outline-none"
        >
          <BiUndo size={40} /> undo
        </button>
        <span className="font-semibold text-sm">All Changes Saved</span>
        <button
          onClick={() => dispatch(redo())}
          className="flex hover:bg-gray-800 px-3 items-center gap-1 capitalize outline-none"
        >
          <BiRedo size={40} /> redo
        </button>
      </div>
      <div>
        <div className="py-1 flex flex-col gap-4">
          <Link to="/dashboard" className="flex w-30 h-10 ">
            <img
              src={brandlogo}
              alt="Creative Ideas"
              className="w-full h-full object-contain"
            />
          </Link>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="bg-green-900 text-center rounded-full h-8 w-9 text-xl font-semibold">
          A
        </div>
        <button
          onClick={initiateDownload}
          className="bg-gray-600 py-1 px-3 rounded shadow-md text-sm font-semibold capitalize"
        >
          download
        </button>
        <button className="bg-blue-600 py-1 px-3 rounded shadow-md flex items-center gap-2 text-sm font-semibold capitalize">
          <FaShareAlt /> Share
        </button>
        <button className="bg-yellow-600 py-1 px-3 rounded shadow-md flex items-center gap-2 text-sm font-semibold capitalize">
          <FaCrown /> Get Premium
        </button>
      </div>
    </div>
  );
};
