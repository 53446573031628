import { useRef, useEffect } from "react";
import { Text, Transformer } from "react-konva";
import { EditableTextInput } from "./EditableTextInput";
import { useDispatch } from "react-redux";
import { editTextPosition } from "../../redux/slices/media-slice";

export function ResizableText({
  x,
  y,
  text,
  isSelected,
  width,
  id,
  onResize,
  onClick,
  onDoubleClick,
  editing,
  fontSize,
  onChange,
  onkeyDown,
  fill,
  size,
  font,
  height,
}) {
  const textRef = useRef(null);
  const transformerRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSelected && transformerRef.current !== null) {
      transformerRef.current.nodes([textRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  function handleResize() {
    if (textRef.current !== null) {
      const textNode = textRef.current;
      const newWidth = textNode.width() * textNode.scaleX();
      const newHeight = textNode.height() * textNode.scaleY();
      textNode.setAttrs({
        width: newWidth,
        scaleX: 1,
      });
      onResize(newWidth, newHeight);
    }
  }

  const handleDragEnd = (e) => {
    dispatch(
      editTextPosition({
        id: id,
        x: e.target.x(),
        y: e.target.y(),
      })
    );
  };

  const transformer = isSelected ? (
    <Transformer
      ref={transformerRef}
      rotateEnabled={false}
      flipEnabled={false}
      enabledAnchors={["middle-left", "middle-right"]}
      boundBoxFunc={(oldBox, newBox) => {
        newBox.width = Math.max(30, newBox.width);
        return newBox;
      }}
    />
  ) : null;

  return (
    <>
      {editing ? (
        <EditableTextInput
          width={width}
          height={height}
          value={text}
          fill={fill}
          fontFamily={font}
          size={fontSize}
          onClick={onClick}
          onChange={onChange}
          onKeyDown={onkeyDown}
          x={x}
          y={y}
        />
      ) : (
        <Text
          x={x}
          y={y}
          ref={textRef}
          text={text}
          fill={fill ? fill : "black"}
          fontFamily={font}
          fontSize={size}
          perfectDrawEnabled={false}
          onTransform={handleResize}
          onClick={onClick}
          onTap={onClick}
          onDblClick={onDoubleClick}
          onDblTap={onDoubleClick}
          width={width}
          draggable
          onDragEnd={handleDragEnd}
        />
      )}
      {transformer}
    </>
  );
}
