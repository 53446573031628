import React, { useEffect, useRef, useState } from "react";

const ScaleOption = () => {
  const sliderRef = useRef();
  const [sliderValue, setSliderValue] = useState(0);

  useEffect(() => {
    if (sliderRef.current) {
      setSliderValue(sliderRef.current.value);
    }
  }, [sliderRef]);
  return (
    <div className=" flex bg-white  w-52 z-50 absolute top-14 left-3 rounded shadow-lg">
      <div className="p-3 flex items-center gap-5 cursor-pointer w-full">
        <input
          type="range"
          ref={sliderRef}
          onChange={(e) => setSliderValue(e.target.value)}
        />
        <span>{sliderValue}</span>
      </div>
    </div>
  );
};

export default ScaleOption;
