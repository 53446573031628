import { useState } from "react";
import { CiFolderOn } from "react-icons/ci";
import { GoUpload } from "react-icons/go";
import { TbPhotoSquareRounded } from "react-icons/tb";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { showOnBoard } from "../../redux/slices/media-slice";

const BackgroundPhoto = ({ options }) => {
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(null);
  const close = () => setShow(false);
  const dispatch = useDispatch();
  const handleClick = (index, item) => {
    dispatch(showOnBoard(item.urls.thumb));
    setSelected(index);
  };
  return (
    <>
      {options.length > 0 && show && (
        <>
          <button
            className={`px-3 ml-2 text-xs py-1 my-2 rounded shadow bg-blue-500 text-white font-semibold hover:bg-blue-400 `}
            onClick={close}
          >
            Back
          </button>
          <div className="h-56 overflow-auto grid grid-cols-2 gap-2 py-2 px-3">
            {options.map((item, index) => (
              <img
                src={item.urls.thumb}
                onClick={() => handleClick(index, item)}
                className={`w-24 h-24 cursor-pointer hover:opacity-60 rounded shadow-md ${
                  selected == index && " border-2 border-blue-500"
                }`}
                key={index}
              />
            ))}
          </div>
        </>
      )}
      {!show && (
        <div className="p-3 flex flex-col gap-2 cursor-pointer">
          <div className="flex items-center gap-2 text-xs capitalize hover:bg-gray-300 py-2 px-1">
            <GoUpload /> upload photo
          </div>
          <div className="flex items-center gap-2 text-xs capitalize hover:bg-gray-300 py-2 px-1">
            <CiFolderOn /> my files
          </div>
          <div
            onClick={() => setShow(true)}
            className="flex items-center gap-2 text-xs capitalize hover:bg-gray-300 py-2 px-1"
          >
            <TbPhotoSquareRounded />
            photo library
          </div>
        </div>
      )}
    </>
  );
};
const BackgroundVideo = () => {
  return (
    <div className="p-3 flex flex-col gap-2 cursor-pointer w-full">
      <div className="flex items-center gap-2 text-xs capitalize hover:bg-gray-300 py-2 px-1">
        <GoUpload /> upload video
      </div>
      <div className="flex items-center gap-2 text-xs capitalize hover:bg-gray-300 py-2 px-1">
        <CiFolderOn /> my files
      </div>
      <div className="flex items-center gap-2 text-xs capitalize hover:bg-gray-300 py-2 px-1 ">
        <TbPhotoSquareRounded />
        video library
      </div>
    </div>
  );
};

const BackgroundOption = ({ background }) => {
  const [selected, setSelected] = useState("photo");

  return (
    <div className=" flex bg-white  w-80 z-50 absolute top-14 left-3 rounded shadow-lg">
      <div className="flex flex-col gap-2 py-2 bg-gray-300">
        <button
          className={`text-xs px-4 capitalize  py-2 ${
            selected == "photo"
              ? "bg-white text-indigo-600 border-l-2 border-indigo-600"
              : ""
          }`}
          onClick={() => setSelected("photo")}
        >
          photos
        </button>
        <button
          className={`text-xs px-4 capitalize py-2 ${
            selected == "video"
              ? "bg-white text-indigo-600 border-l-2 border-indigo-600"
              : ""
          }`}
          onClick={() => setSelected("video")}
        >
          videos
        </button>
      </div>
      <div className="flex-1">
        {selected == "photo" && <BackgroundPhoto options={background} />}
        {selected == "video" && <BackgroundVideo />}
      </div>
    </div>
  );
};

export default BackgroundOption;

BackgroundPhoto.propTypes = {
  options: PropTypes.array,
};
BackgroundOption.propTypes = {
  background: PropTypes.array,
};
