const priceSectionData = [
  {
    id: 1,
    firstH4Style:
      "pr-2 w-1/2 underline underline-offset-4 decoration-stemGreen text-white text-right text-md text-base font-nunitoExtraBold",
    secondH4Style:
      "pl-2 w-1/2 underline underline-offset-4 decoration-stemGreen text-white text-left text-base font-nunitoExtraBold",
    firstH4Text: "Users",
    secondH4Text: "Projects",
    parentStyle: "pr-12 md:pr-0 w-3/4 md:w-full justify-center gap-20 md:gap-8",
  },
  {
    id: 2,
    firstH4Style:
      "w-1/2 pr-2 md:pr-0 flex-1 text-white md:text-right font-nunitoExtraBold text-5xl",
    secondH4Style:
      "w-1/2 pl-2 md:pl-0 flex-1 text-white md:text-left font-nunitoExtraBold text-5xl",
    firstH4Text: 1023,
    secondH4Text: 197300,
    parentStyle:
      "w-full md:w-full md:justify-center pl-8 pr-16 md:px-0 gap-0 md:gap-8",
  },
  // {
  //   id: 3,
  //   firstH4Style: "font-nunitoExtraBold text-center text-7xl text-stemGreen",
  //   secondH4Style: "hidden",
  //   firstH4Text: "",
  //   secondH4Text: "",
  //   parentStyle: "",
  // },
  {
    id: 4,
    firstH4Style:
      "w-4/5 md:w-3/4 leading-7 md:leading-8 mx-auto text-white text-center font-nunitoRegular text-lg",
    secondH4Style: "hidden",
    firstH4Text:
      "Quality video and photo editing doesn't have to break the bank. Our competitive ensures that our services are accessible to all.",
    secondH4Text: "",
    parentStyle: "",
  },
];

export default priceSectionData;
