import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import App from "./App.jsx";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import "./index.css";
import HomePage from "./pages/HomePage.jsx";
import FeaturesPage from "./pages/FeaturesPage.jsx";
import RegisterPage from "./pages/RegisterPage.jsx";
import LoginPage from "./pages/LoginPage.jsx";
import AboutPage from "./pages/AboutPage.jsx";
import PricingPage from "./pages/PricingPage.jsx";
import CheckoutPage from "./pages/CheckOutPage.jsx";
import TemplatesPage from "./pages/TemplatesPage.jsx";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage.jsx";
import ForgotPasswordPage from "./pages/ForgotPasswordPage.jsx";
import ContactUsPage from "./pages/ContactUsPage.jsx";
import { Provider } from "react-redux";
import store from "./redux/store.js";
import ExportOrSaveVideoPage from "./pages/ExportOrSaveVideoPage.jsx";
import AllDesignsOverviewPage from "./pages/AllDesignsOverviewPage.jsx";
import AllDesignsSearchPage from "./pages/AllDesignsSearchPage.jsx";
import AllDesignsRecentsPage from "./pages/AllDesignsRecentsPage.jsx";
import AllDesignsProjectsPage from "./pages/AllDesignsProjectsPage.jsx";
import AllDesignsSettingsPage from "./pages/AllDesignsSettingsPage.jsx";
import AllDesignsSubscriptionPage from "./pages/AllDesignsSubscriptionPage.jsx";
import AllDesignsPasswordPage from "./pages/AllDesignsPasswordPage.jsx";
import BlogsPage from "./pages/BlogsPage.jsx";
import SingleBlogPage from "./pages/SingleBlogPage.jsx";
import VideoEditingPage from "./pages/VideoEditingPage.jsx";
import VideoEditingDashboard from "./pages/VideoEditingDashboard.jsx";
import ImageEditingDashboard from "./pages/ImageEditingDashboard.jsx";
import AllDesignsPreferencesPage from "./pages/AllDesignsPreferencesPage.jsx";
import AllDesignsTemplatesPage from "./pages/AllDesignsTemplatesPage.jsx";
import AllDesignsUploadsPage from "./pages/AllDesignsUploadsPage.jsx";
import AllDesignsDownloadsPage from "./pages/AllDesignsDownloadsPage.jsx";
import ResetPasswordPage from "./reset-password-components/ResetPasswordPage.jsx";
import AllDesignsAccountDeletionPage from "./pages/AllDesignsAccountDeletionPage.jsx";
import SubscriptionLoginCheckOut from "./pages/SubscriptionLoginCheckOut.jsx";
import { PolontoCanvas } from "./pages/PolontoCanvas.jsx";
import CheckPage from "./pages/CheckPage.jsx";
import Editor from "./pages/Editor.jsx";

function switchApiURL() {
  if (import.meta.env.VITE_ENV === "dev")
    return import.meta.env.VITE_API_URL_DEV;
  return import.meta.env.VITE_API_URL_PROD;
}

const baseurl = switchApiURL();
const queryClient = new QueryClient();
const router = createBrowserRouter(
  [
    {
      path: "/",
      element: (
        <Provider store={store}>
          <App />
        </Provider>
      ),
      children: [
        {
          path: "/",
          element: <HomePage />,
        },

        {
          path: "features",
          element: <FeaturesPage />,
        },
        {
          path: "register",
          element: <RegisterPage baseurl={baseurl} />,
        },
        {
          path: "login",
          element: <LoginPage baseurl={baseurl} />,
        },
        {
          path: "about-us",
          element: <AboutPage />,
        },
        {
          path: "contact-us",
          element: <ContactUsPage />,
        },
        {
          path: "pricing",
          element: <PricingPage />,
        },
        {
          path: "checkOut/:type/:duration",
          element: <CheckoutPage />,
        },
        {
          path: "templates/:filename",
          element: <TemplatesPage />,
        },
        {
          path: "templates",
          element: <TemplatesPage />,
        },
        {
          path: "terms-and-conditions",
          element: <TermsAndConditionsPage />,
        },
        {
          path: "forgot-password",
          element: <ForgotPasswordPage baseurl={baseurl} />,
        },
        // {
        //   path: "image-editor",
        //   // element: <ImageEditingDashboard />,
        //   element: <PolontoCanvas />,
        // },
        {
          path: "image-editor",
          // element: <ImageEditingDashboard />,
          element: <Editor />,
        },
        {
          path: "video-editor",
          element: <VideoEditingDashboard />,
        },
        {
          path: "video",
          element: <ExportOrSaveVideoPage />,
        },
        {
          path: "video-editing",
          element: <VideoEditingPage />,
        },
        {
          path: "dashboard",
          element: <AllDesignsOverviewPage />,
        },
        {
          path: "dashboard/search",
          element: <AllDesignsSearchPage />,
        },
        {
          path: "dashboard/recents",
          element: <AllDesignsRecentsPage />,
        },
        {
          path: "dashboard/projects",
          element: <AllDesignsProjectsPage />,
        },
        {
          path: "dashboard/settings",
          element: <AllDesignsSettingsPage />,
        },
        {
          path: "dashboard/preferences",
          element: <AllDesignsPreferencesPage />,
        },
        {
          path: "dashboard/uploads",
          element: <AllDesignsUploadsPage />,
        },
        {
          path: "dashboard/downloads",
          element: <AllDesignsDownloadsPage />,
        },
        // {
        //   path: "dashboard/templates",
        //   element: <AllDesignsTemplatesPage />,
        // },
        {
          path: "dashboard/settings/subscriptions/:param",
          element: <AllDesignsSubscriptionPage />,
        },
        {
          path: "dashboard/settings/subscriptions/:param/:type/:duration",
          element: <SubscriptionLoginCheckOut />,
        },
        {
          path: "dashboard/settings/password/:param",
          element: <AllDesignsPasswordPage />,
        },
        {
          path: "/dashboard/settings/account/:param",
          element: <AllDesignsAccountDeletionPage />,
        },
        {
          path: "blogs",
          element: <BlogsPage />,
        },
        {
          path: "blogs/:blogId",
          element: <SingleBlogPage />,
        },
      ],
    },
    {
      path: "/check/:check",
      element: <CheckPage />,
    },
    {
      path: "reset-password/:key",
      element: <ResetPasswordPage />,
    },
  ],
  {
    basename: "/",
  }
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);
