import React, { useEffect, useRef, useState } from "react";
import { Header } from "../components/editor/Header";
import SideMenu from "../components/editor/SideMenu";
import EditingBoard from "../components/editor/EditingBoard";
import { fetchBackground, uploadImages } from "../baseURL";
import { useNavigate } from "react-router-dom";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { onLoading } from "../redux/actions";
import { FullLoader } from "../components/globals/Loader";
// import { fetchBackground } from "../../http";

const Editor = () => {
  const [background, setBackground] = useState([]);
  const boardRef = useRef();
  const auth = JSON.parse(localStorage.getItem("userswiftsketch"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader.loading);

  useEffect(() => {
    if (!auth) {
      navigate("/login", { replace: true });
    }
  }, [auth, navigate]);
  useEffect(() => {
    (async () => {
      const response = await fetchBackground();
      if (response.status == 200) {
        console.log(response.data);
        setBackground(response.data.results);
      }
    })();
  }, []);

  const downloadURI = async (uri, name) => {
    try {
      dispatch(onLoading(true));
      var link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      const data = {
        file: uri,
        upload_preset: "mjr7evpg",
        folder: `imageexport/${auth.email.split("@")[0]}${auth.userId}`,
      };
      const sendingInformation = {
        data: { ...data },
        user_id: auth.userId,
      };
      const response = await uploadImages(sendingInformation);
      console.log(response);
      if (response.status == 200) {
        dispatch(onLoading(false));

        toast.success(`${response.data.message}`, {
          position: "top-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (error) {
      dispatch(onLoading(false));

      toast.error(`An error occured`, {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const handleDownload = () => {
    if (boardRef.current) {
      const uri = boardRef.current.toDataURL();
      console.log("Image URI:", uri);
      downloadURI(uri, "swiftsketch");
      // You can now trigger the download using this URI
    }
  };

  return (
    <>
      <ToastContainer />

      {isLoading && <FullLoader loaderText="saving to projects" isColored />}
      <div className="flex h-screen bg-white dark:bg-slate-900 overflow-hidden">
        <section className="flex flex-col w-[90%] sm:w-[100%]">
          <div className="h-screen">
            <Header initiateDownload={handleDownload} />
            <section className="flex h-full">
              <div>
                <SideMenu />
              </div>
              <div className="flex-1 h-full overflow-hidden">
                <EditingBoard ref={boardRef} fetchedBackground={background} />
              </div>
            </section>
          </div>
        </section>
      </div>
    </>
  );
};

export default Editor;
