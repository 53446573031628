import { AiOutlineBlock } from "react-icons/ai";
import { BsCardHeading } from "react-icons/bs";
import { FaFolderPlus, FaPhotoVideo, FaSquareFull } from "react-icons/fa";
import { FaCircle } from "react-icons/fa6";
import { IoTriangle } from "react-icons/io5";
import { MdBrush, MdOutlineCallMade, MdPhoto } from "react-icons/md";
import { RiFontSize } from "react-icons/ri";
import { TbLetterT } from "react-icons/tb";
import Basic from "../sidebar/Basic";
import { useState } from "react";
import Photos from "../sidebar/Photos";
import Videos from "../sidebar/Videos";
import Files from "../sidebar/Files";

const SideMenu = () => {
  const [show, setShow] = useState("basic");
  const [active, setActive] = useState("basic");

  const changeMenu = (key) => {
    setShow(key);
    setActive(key);
  };
  return (
    <div className="flex items-start">
      <div className="flex flex-col items-center w-fit text-white text-xs font-semibold justify-center">
        <div
          className={`flex flex-col items-center justify-center py-3 px-5 hover:bg-slate-950/50 ${
            active == "basic" ? "border-l-4 border-indigo-700" : ""
          } `}
          onClick={() => changeMenu("basic")}
        >
          <AiOutlineBlock size={40} />
          <span>Basic</span>
        </div>
        <div
          className={`flex flex-col items-center justify-center py-3 px-5 hover:bg-slate-950/50 ${
            active == "photos" ? "border-l-4 border-indigo-700" : ""
          } `}
          onClick={() => changeMenu("photos")}
        >
          <MdPhoto size={40} />
          <span>Photos</span>
        </div>
        <div
          className={`flex flex-col items-center justify-center py-3 px-5 hover:bg-slate-950/50 ${
            active == "videos" ? "border-l-4 border-indigo-700" : ""
          } `}
          onClick={() => changeMenu("videos")}
        >
          <FaPhotoVideo size={40} />
          <span>Videos</span>
        </div>
        <div
          className={`flex flex-col items-center justify-center py-3 px-5 hover:bg-slate-950/50 ${
            active == "files" ? "border-l-4 border-indigo-700" : ""
          } `}
          onClick={() => changeMenu("files")}
        >
          <FaFolderPlus size={40} />
          <span>My Files</span>
        </div>
      </div>
      <div className="bg-gray-600 w-[300px] h-[88vh]">
        {show == "basic" && <Basic />}
        {show == "photos" && <Photos />}
        {show == "videos" && <Videos />}
        {show == "files" && <Files />}
      </div>
    </div>
  );
};

export default SideMenu;
