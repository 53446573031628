import { useEffect, useRef } from "react";
import { RegularPolygon, Transformer } from "react-konva";

const Triangle = ({ shape, handleClick, selected }) => {
  const triRef = useRef();
  const trRef = useRef();
  useEffect(() => {
    if (selected) {
      trRef.current?.nodes([triRef.current]);
      trRef.current?.getLayer()?.batchDraw();
    } else {
      trRef.current?.nodes([]);
      trRef.current?.getLayer()?.batchDraw();
    }
  }, [selected]);
  return (
    <>
      <RegularPolygon
        onClick={handleClick}
        ref={triRef}
        x={shape.x}
        y={shape.y}
        sides={shape.sides}
        radius={shape.radius}
        fill={shape.fill}
        draggable
      />
      <Transformer
        ref={trRef}
        resizeEnabled={true}
        rotateEnabled={true}
        boundBoxFunc={(oldBox, newBox) => {
          if (newBox.width < 9 || newBox.height < 9) {
            return oldBox;
          }
          return newBox;
        }}
      />
    </>
  );
};

export default Triangle;
