import { Fragment, useEffect, useState } from "react";
import Navbar from "./components/globals/Navbar";
import Footer from "./components/globals/Footer";
import GetStarted from "./components/globals/GetStarted";
import "react-toastify/dist/ReactToastify.css";

import { Outlet, useLocation } from "react-router-dom";

function App() {
  const [isHidden, setIsHidden] = useState(true);

  const location = useLocation();
  const { pathname } = location;

  const isHidePathNames = [
    "/register",
    "/login",
    "/checkout",
    "/forgot-password",
    "/dashboard",
    "/image-editor",
    "/image-editor-new",
    "/video-editor",
    "video/export",
    "reset-password",
    "/demo-editor",
    "/dashboard",
    "/reset-password",
    "/loadercheck",
  ];
  const isPageCheck = pathname.split("/").find((item) => item);

  useEffect(() => {
    const hideNavAndFooter = () => {
      if (
        isHidePathNames.includes(pathname) ||
        isPageCheck === "dashboard" ||
        isPageCheck === "image-editor" ||
        isPageCheck === "video-editor" ||
        isPageCheck === "demo-editor" ||
        isPageCheck === "video" ||
        isPageCheck === "dashboard" ||
        isPageCheck === "reset-password" ||
        isPageCheck === "dashboard" ||
        isPageCheck === "image-editor" ||
        isPageCheck === "image-editor-new" ||
        isPageCheck === "video-editor" ||
        isPageCheck === "demo-editor" ||
        isPageCheck === "video" ||
        isPageCheck === "dashboard"
      )
        return setIsHidden(false);
      return setIsHidden(true);
    };
    hideNavAndFooter();
  });
  return (
    <Fragment>
      {isHidden && <Navbar />}
      <Outlet />
      {isHidden && <GetStarted />}
      {isHidden && <Footer />}
    </Fragment>
  );
}

export default App;
