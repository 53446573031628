import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  image: [],
  texts: [],
  shapes: [],
  template: null,
  filters: [],
  horizontalFlip: { scale: undefined, x: false },
  verticalFlip: { scale: undefined, y: false },
  history: [],
  redoHistory: [],
  selected: null,
  selectedText: null,
};

const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
    showOnBoard(state, action) {
      state.image = [...state.image, action.payload];
      state.history.push({ type: "showOnBoard", payload: action.payload });
      state.redoHistory = [];
    },
    addTemplate(state, action) {
      state.template = {
        image: action.payload.image,
        text: action.payload.text,
      };
      state.history.push({
        type: "template",
        payload: { image: action.payload.image, text: action.payload.text },
      });
      state.redoHistory = [];
    },
    addText(state, action) {
      state.texts = [...state.texts, action.payload];
      state.history.push({ type: "texts", payload: action.payload });
      state.redoHistory = [];
    },
    editText(state, action) {
      const existing = state.texts.find((item) => item.id == action.payload.id);
      existing.text = action.payload.text;
    },
    editFont(state, action) {
      const existing = state.texts.find((item) => item.id == action.payload.id);
      existing.fontFamily = action.payload.fontFamily;
    },
    editTextPosition(state, action) {
      const existing = state.texts.find((item) => item.id == action.payload.id);
      existing.x = action.payload.x ?? existing.x;
      existing.y = action.payload.y ?? existing.y;
    },
    addShapes(state, action) {
      state.shapes = [...state.shapes, action.payload];
      state.history.push({ type: "addShapes", payload: action.payload });
      state.redoHistory = [];
    },
    addFilters(state, action) {
      const { type } = action.payload;
      const existingFilterIndex = state.filters.findIndex(
        (item) => item.type === type
      );

      if (existingFilterIndex !== -1) {
        state.filters = state.filters.filter((item) => item.type !== type);
      } else {
        state.filters = [...state.filters, action.payload];
      }
      state.history.push({ type: "addFilters", payload: action.payload });
      state.redoHistory = [];
    },
    addHorizontalFlip(state) {
      if (state.horizontalFlip.scale !== undefined) {
        state.horizontalFlip = { scale: undefined, x: false };
      } else {
        state.horizontalFlip = { scale: -1, x: true };
      }
      state.history.push({
        type: "addHorizontalFlip",
        payload: state.horizontalFlip,
      });
      state.redoHistory = [];
    },
    addSelected(state, action) {
      if (action.payload !== state.selected) {
        state.selected = action.payload;
      } else {
        state.selected = null;
      }
    },
    addSelectedFont(state, action) {
      console.log(action.payload);
      state.selectedText = action.payload;
    },
    addColor(state, action) {
      console.log(action.payload);
      console.log(state.selected);
      const shape = state.shapes.find((item) => item.id == state.selected);
      shape.fill = action.payload;
    },
    addVerticalFlip(state) {
      if (state.verticalFlip.scale !== undefined) {
        state.verticalFlip = { scale: undefined, y: false };
      } else {
        state.verticalFlip = { scale: -1, y: true };
      }
      state.history.push({
        type: "addVerticalFlip",
        payload: state.verticalFlip,
      });
      state.redoHistory = [];
    },
    undo(state) {
      const lastAction = state.history.pop();
      if (!lastAction) return; // No actions to undo

      // Push the undone action to redoHistory
      state.redoHistory.push(lastAction);

      switch (lastAction.type) {
        case "showOnBoard":
          state.image = state.image.slice(0, -1);
          break;
        case "texts":
          state.texts = state.texts.slice(0, -1);
          break;
        case "addShapes":
          state.shapes = state.shapes.slice(0, -1);
          break;
        case "addFilters":
          state.filters = state.filters.filter(
            (item) => item.type !== lastAction.payload.type
          );
          break;
        case "addHorizontalFlip":
          state.horizontalFlip = { scale: undefined, x: false };
          break;
        case "addVerticalFlip":
          state.verticalFlip = { scale: undefined, y: false };
          break;
        case "template":
          state.template = null;
          break;
        default:
          break;
      }
    },
    redo(state) {
      const lastRedoAction = state.redoHistory.pop();
      if (!lastRedoAction) return;

      state.history.push(lastRedoAction);

      switch (lastRedoAction.type) {
        case "showOnBoard":
          state.image = [...state.image, lastRedoAction.payload];
          break;
        case "texts":
          state.texts = [...state.texts, lastRedoAction.payload];
          break;
        case "addShapes":
          state.shapes = [...state.shapes, lastRedoAction.payload];
          break;
        case "addFilters":
          state.filters = [...state.filters, lastRedoAction.payload];
          break;
        case "addHorizontalFlip":
          state.horizontalFlip = lastRedoAction.payload;
          break;
        case "addVerticalFlip":
          state.verticalFlip = lastRedoAction.payload;
          break;
        case "template":
          state.template = lastRedoAction.payload;
          break;
        default:
          break;
      }
    },
  },
});

export const {
  showOnBoard,
  addShapes,
  addFilters,
  addHorizontalFlip,
  addVerticalFlip,
  addTemplate,
  undo,
  redo,
  addSelected,
  addColor,
  addText,
  editText,
  editFont,
  addSelectedFont,
  editTextPosition,
} = mediaSlice.actions;
export default mediaSlice.reducer;
